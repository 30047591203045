import posthog from "posthog-js"
// eslint-disable camelcase
const analytics = {
  init: () => {
    if (window?.LoveIrish?.apiKeys?.posthog) {
      let distinctId = ""
      posthog.init(window.LoveIrish.apiKeys.posthog, {
        loaded: (result) => {
          distinctId = result.get_distinct_id()
        },
        capture_pageview: true,
        capture_pageleave: true,
        autocapture: {
          dom_event_allowlist: ["click", "change", "submit"],
          element_attribute_ignorelist: ["data-no-track", '[type*="password"]'],
        },
        api_host: "https://us.i.posthog.com",
      })
      if (distinctId !== window.LoveIrish.analytics.sessionId) {
        posthog.alias(distinctId, window.LoveIrish.analytics.sessionId)
      }
    }
  },
  track: (event, props = {}) => posthog.capture(event, props),
  trackPageView: () => posthog.capture("$pageview"),
  /**
   *
   * @param label {string} snake_cased string that should have context like blog_post_card when doing a breakdown of link clicks.
   * @param href {url}
   * @param location {[string]} where on the page this components renders (header, footer) that can be used to segment if this link
   * appears more than once on a page
   * @return {CaptureResult | void}
   */

  trackLinkClick: ({ label, href, location = [] }) =>
    posthog.capture("clicked#link", {
      // TODO remove these once we have passed the grace period where this is still used
      link_href: href,
      link_label: label,
      link_location: location,
      component_href: href,
      component_label: label,
      component_location: location,
    }),

  trackFormSubmit: ({ label, success, location = [], ...props }) =>
    posthog.capture("form#submit", {
      component_label: label,
      component_location: location,
      success,
      ...props,
    }),
}
// eslint-enable camelcase

export default analytics
