import React from "react"
import Excerpt from "design_system/core/components/excerpt"
import { HiOutlineHeart } from "react-icons/hi2"
import Title from "design_system/core/components/title"
import PropTypes from "prop-types"
import { Question } from "design_system/utils/types/item"

function CommunityQuestionNotice() {
  return (
    <div className="rounded-md py-3 px-5 inline-block border border-solid border-warning-200">
      <div className="flex items-center">
        <div className="flex-shrink-0">
          <HiOutlineHeart className="h-5 w-5 text-error" aria-hidden="true" />
        </div>
        <div className="ml-3">
          <p className="m-0 text-xs text-warning-700 align-middle">
            This question was submitted by a member of our community.
          </p>
        </div>
      </div>
    </div>
  )
}

const InterviewQuestionItem = ({
  id,
  name,
  summary,
  content,
  source = "moderator",
  answers = [],
}: Question) => {
  const anchor = `question-${id}`
  return (
    <div
      className="border-l border-solid border-gray-100 p-5 target:border-warning-300 target:bg-warning-50"
      id={anchor}
    >
      <h3 className="text-xs uppercase text-gray-400 mb-2">
        Question from the {source}
      </h3>

      <Title href={{ hash: anchor }} title={name} size="sm" />
      <Excerpt
        lines="full"
        className="text-gray-600 prose first-of-type:prose-p:mt-0 last-of-type:prose-p:mb-0 mt-2"
      >
        {content || summary}
      </Excerpt>

      {source === "community" && (
        <div className="mt-2 mb-3">
          <CommunityQuestionNotice />
        </div>
      )}

      {answers.map((answer) => (
        <div className="ml-6" key={answer.id}>
          <h4 className="text-xs uppercase text-gray-400 mb-2 mt-4">
            Response
          </h4>
          <Title title={answer.name} size="xs" />
          <Excerpt
            lines="full"
            className="prose text-gray-600 first-of-type:prose-p:mt-0 mt-2"
          >
            {answer.summary || answer.content}
          </Excerpt>
        </div>
      ))}
    </div>
  )
}

InterviewQuestionItem.propTypes = {
  source: PropTypes.oneOf(["moderator", "community"]),
}

export default InterviewQuestionItem
